import { useContext } from 'react';
import { Context, ContextValue } from 'context';

export function useAppContext(): ContextValue {
  const ctx: ContextValue = useContext(Context);
  return ctx;
}

type Selector<T> = (ctx: ContextValue) => T;

export function useAppContextSelector<T = any>(selector: Selector<T>): T {
  const ctx: ContextValue = useContext(Context);
  return selector(ctx);
}
