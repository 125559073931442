import React, { useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Button, Grid } from 'basis';
import { Routes } from 'types';
import { LayoutSection } from 'components';

const backButtonRoutes = [Routes.verify];

type Props = {
  loading?: boolean;
  submitButtonLabel?: string;
};

export const FormButtons: React.FC<Props> = ({ loading, submitButtonLabel }) => {
  const history = useHistory();
  const { pathname } = useLocation();
  const hasBackButton = backButtonRoutes.includes(pathname as Routes);

  const onBackClick = useCallback(() => {
    history.goBack();
  }, [history]);

  return (
    <LayoutSection>
      <Grid rowsGap="4" colsGap="2">
        {hasBackButton && (
          <Grid.Item colSpan="all" rowSpan="1" colSpan-sm="0-2" rowSpan-sm="0">
            <Grid>
              <Button
                testId="back-button"
                type="button"
                variant="secondary"
                disabled={loading}
                onClick={onBackClick}
              >
                Back
              </Button>
            </Grid>
          </Grid.Item>
        )}
        <Grid.Item colSpan="all" colSpan-sm={hasBackButton ? '5-7' : 'all'}>
          <Grid>
            <Button type="submit" loading={loading} testId="submit-button">
              {submitButtonLabel}
            </Button>
          </Grid>
        </Grid.Item>
      </Grid>
    </LayoutSection>
  );
};
