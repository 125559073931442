import { Actions, Action } from './actions';
import { State } from 'types';
import { initialState } from './initialState';

export const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case Actions.SET_CONTACT_DETAILS:
      return {
        ...state,
        contactDetails: {
          ...state.contactDetails,
          ...action.data,
        },
      };
    case Actions.SET_AUTH: {
      const { accessToken, latitudeId, mobileNumber } = action.data;
      return {
        ...state,
        accessToken: accessToken || state.accessToken,
        latitudeId: latitudeId || state.latitudeId,
        contactDetails: {
          ...state.contactDetails,
          mobileNumber: mobileNumber || state.contactDetails.mobileNumber,
        },
      };
    }
    case Actions.SET_VERIFICATION: {
      const { verificationLocked, maskedMobileNumber, oktaAuthTxn } = action.data;
      return {
        ...state,
        verification: {
          verificationLocked: verificationLocked || state.verification.verificationLocked,
          maskedMobileNumber: maskedMobileNumber || state.verification.maskedMobileNumber,
          oktaAuthTxn: oktaAuthTxn || state.verification.oktaAuthTxn,
        },
      };
    }
    case Actions.SET_TEALIUM:
      return {
        ...state,
        tealium: {
          ...state.tealium,
          ...action.data,
        },
      };
    case Actions.INIT_FI_CONNECT:
      return {
        ...state,
        fiConnect: {
          ...action.data,
        },
      };
    case Actions.SET_ERROR:
      return {
        ...state,
        error: action.error,
      };
    case Actions.SET_COMPLETE:
      return {
        ...state,
        complete: action.complete,
      };
    case Actions.RESET:
      return initialState;
    default: {
      process.env.REACT_APP_ENV === 'dev' && console.warn('Action not defined', action);
      return state;
    }
  }
};
