import React from 'react';
import { Footer, Link, Text } from 'basis';
import { config } from '_config';
import { FooterLinkKey, FooterLinks } from './Footer';

type Props = {
  trackEventSender: (label: string) => void;
  getLinkWithAnalytics: (key: FooterLinkKey) => string;
  footerLinks: FooterLinks;
};

export const DefaultFooter: React.FC<Props> = ({ trackEventSender, getLinkWithAnalytics, footerLinks }) => {
  return (
    <Footer>
      <Footer.Header testId="footer">
        <Footer.Header.Logo name={config.countryCode === 'NZ' ? 'gem' : 'latitude'} />
      </Footer.Header>
      <Footer.Legal testId="footer">
        <Footer.Legal.Links>
          {Object.keys(footerLinks).map((k) => {
            const key = k as FooterLinkKey;
            return (
              <Link
                key={key}
                href={getLinkWithAnalytics(key)}
                newTab
                onClick={() => trackEventSender(footerLinks[key].label)}
              >
                {footerLinks[key].label}
              </Link>
            );
          })}
        </Footer.Legal.Links>
        <Footer.Legal.Copy>
          <Text>Gem Visa is provided by Latitude Financial Services Limited.</Text>
        </Footer.Legal.Copy>
      </Footer.Legal>
    </Footer>
  );
};
