import React, { CSSProperties, ReactNode } from 'react';
import ReactDOM from 'react-dom';
import { Icon } from 'basis';
import * as s from './Modal.styles';
import { useFocusLock, usePortalElement, useScrollLock } from './utils';

export interface Props {
  onClose: () => void;
  children?: ReactNode;
  style?: CSSProperties;
  testId?: string;
  disableClose?: boolean;
}

export const Modal = (props: Props) => {
  const { onClose, children, style, disableClose, testId = 'modal' } = props;
  const node = usePortalElement();
  useScrollLock();
  useFocusLock(node);

  if (!node) {
    return null;
  }

  return ReactDOM.createPortal(
    <s.ModalContainer data-testid={testId}>
      <s.ModalBackdrop data-testid="modal-backdrop" onClick={disableClose ? () => null : onClose} />
      <s.ModalWrapper>
        <s.Modal style={style} data-testid="Modal">
          {!disableClose && (
            <s.ModalClose data-testid="modal-close" type="button" onClick={onClose}>
              <Icon name="cross" color="grey.t75" />
            </s.ModalClose>
          )}
          {children}
        </s.Modal>
      </s.ModalWrapper>
    </s.ModalContainer>,
    node,
  );
};
