import React from 'react';
import { Grid } from 'basis';
import { LayoutSection } from 'components';

type Props = {
  children: React.ReactNode;
};

export const LayoutForm: React.FC<Props> = ({ children }) => {
  return (
    <LayoutSection>
      <Grid rowsGap="8">{children}</Grid>
    </LayoutSection>
  );
};
