import React from 'react';
import { BrowserRouter, Route, Switch, useLocation } from 'react-router-dom';
import { Routes } from 'types';
import { Main, Login, Verification, FiConnect, Documents, Payslips, Outcome } from 'views';
import { RouterScrollManager, RouterRedirectManager } from 'utils';
import { useTrackingPageView } from 'hooks';
import { config } from '_config';
import {Acceptance} from "./views/acceptance/Acceptance";
import {AcceptanceConfirmation} from "./views/acceptance-confirmation/AcceptanceConfirmation";

const ROUTES = [
  Routes.init,
  Routes.verify,
  Routes.main,
  Routes.connect,
  Routes.documents,
  Routes.payslips,
  Routes.outcome,
];

export const RouteHandlers: React.FC = () => {
  const { pathname } = useLocation();
  const { siteSubSection, tealiumPageTitle } = config.routeConfig[pathname as Routes];
  const is404 = !ROUTES.includes(pathname as Routes);

  useTrackingPageView({ siteSubSection: is404 ? '404' : siteSubSection, pageTitle: tealiumPageTitle });

  return (
    <>
      <RouterScrollManager />
      <RouterRedirectManager />
    </>
  );
};

export const Router: React.FC = () => {
  return (
    <BrowserRouter>
      <RouteHandlers />
      <Switch>
        <Route exact path={Routes.init}>
          <Login />
        </Route>
        <Route path={Routes.verify}>
          <Verification />
        </Route>
        <Route path={Routes.main}>
          <Main />
        </Route>
        <Route path={Routes.connect}>
          <FiConnect />
        </Route>
        <Route path={Routes.documents}>
          <Documents />
        </Route>
        <Route path={Routes.payslips}>
          <Payslips />
        </Route>
        <Route path={Routes.outcome}>
          <Outcome />
        </Route>
        <Route path={Routes.acceptance}>
          <Acceptance />
        </Route>
        <Route path={Routes.acceptanceConfirmation}>
          <AcceptanceConfirmation />
        </Route>
      </Switch>
    </BrowserRouter>
  );
};
