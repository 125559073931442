import React from 'react';
import { Button, Container, Text, Flex } from 'basis';
import { Modal } from 'components';
import { useTheme } from 'theme';
import IllionLogo from 'images/illion-logo-bank-statements.png';

const modalStyle = {
  width: 600,
};

type Props = {
  isOpen?: boolean;
  onClose: () => void;
};

export const FiConnectModal: React.FC<Props> = ({ onClose }) => {
  const theme = useTheme();
  return (
    <Modal onClose={onClose} style={modalStyle} testId="modal-fi-connect">
      <Container padding="3" padding-md="8">
        <Text textStyle="heading4" color={theme.headingColor} margin="0 0 6 0" margin-md="0 0 7 0">
          Sharing your bank statement
        </Text>

        <Container>
          <Text textStyle="body1">
            <b>Securely connecting with your bank online</b>
          </Text>
        </Container>

        <Container padding="6 0 1 0">
          <img src={IllionLogo} alt="IllionLogo" width="120px" />
        </Container>

        <Container>
          <Text textStyle="body2">
            Gem Visa has partnered with illion BankStatements to securely provide digital statements directly from your
            bank. It’s a safe and secure service, using 256-bit bank level encryption.
          </Text>
        </Container>

        <Container padding="6 0">
          <Text textStyle="body2">
            You’ll need to log in using your bank username and password.{' '}
            <b>illion and Gem Visa never store your login details and is a one time access only.</b> This one time
            access gives us a copy of your bank statement. This is the fastest way to get a decision.
          </Text>
        </Container>

        <Flex placeItems="center">
          <Container padding="2">
            <Button width="160px" onClick={onClose}>
              Close
            </Button>
          </Container>
        </Flex>
      </Container>
    </Modal>
  );
};
