import { useEffect, useState } from 'react';

type FocusableElement =
  | HTMLButtonElement
  | HTMLInputElement
  | HTMLSelectElement
  | HTMLTextAreaElement
  | HTMLAnchorElement;

const focusableTypes = 'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])';
const getFocusListener = (modal: HTMLDivElement) => (e: KeyboardEvent) => {
  let isTabPressed = e.key === 'Tab' || e.keyCode === 9;

  if (!isTabPressed) {
    return;
  }

  const focusableElements: NodeListOf<FocusableElement> = modal.querySelectorAll(focusableTypes);
  const firstFocusableElement = focusableElements[0];
  const lastFocusableElement = focusableElements[focusableElements.length - 1];

  if (e.shiftKey) {
    if (document.activeElement === firstFocusableElement) {
      lastFocusableElement.focus();
      e.preventDefault();
    }
  } else if (document.activeElement === lastFocusableElement) {
    firstFocusableElement.focus();
    e.preventDefault();
  }
};

export const useScrollLock = () => {
  useEffect(() => {
    const body = document.body;
    const scrollY = window.scrollY;
    body.style.position = 'fixed';
    body.style.width = '100%';
    body.style.top = -scrollY + 'px';

    return () => {
      body.style.position = '';
      body.style.width = '';
      body.style.top = '';
      window.scrollTo(0, scrollY || 0 * -1);
    };
  }, []);
};

export const useFocusLock = (el: HTMLDivElement | null) => {
  useEffect(() => {
    if (el) {
      const focusListener = getFocusListener(el);
      const activeElement = document.activeElement;
      el.focus({ preventScroll: true });
      document.addEventListener('keydown', focusListener);

      return () => {
        // @ts-ignore
        activeElement && activeElement.focus({ preventScroll: true });
        document.removeEventListener('keydown', focusListener);
      };
    }
  }, [el]);
};

export const usePortalElement = (): HTMLDivElement | null => {
  const [node, setNode] = useState<HTMLDivElement | null>(null);

  useEffect(() => {
    const domNode: HTMLDivElement = document.createElement('div');
    domNode.tabIndex = -1;
    domNode.dataset.testid = 'Portal';
    document.body.appendChild(domNode);
    setNode(domNode);

    return () => {
      document.body.removeChild(domNode);
    };
  }, []);

  return node;
};
