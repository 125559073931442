import { useTheme as useEmotionTheme } from 'emotion-theming';
import { defaultTheme as basisTheme } from 'basis';
import { config } from '_config';

const countrySpecific: { [key: string]: Partial<Theme> } = {
  AU: {
    highlightColor: 'secondary.purple.t30',
  },
  NZ: {
    highlightColor: 'secondary.lightBlue.t25',
  },
};

// console.warn(basisTheme);

type CountryCode = keyof typeof countrySpecific;

export const media = {
  xsMobile: `(min-width: ${basisTheme.breakpoints.xs})`,
  mobile: `(min-width: ${basisTheme.breakpoints.sm})`,
  tablet: `(min-width: ${basisTheme.breakpoints.md})`,
  laptop: `(min-width: ${basisTheme.breakpoints.lg})`,
  desktop: `(min-width: ${basisTheme.breakpoints.xl})`,
};

type Media = typeof media;

export type Theme = {
  headingColor: string;
  headingSize: string;
  highlightColor: string;
  outcomeBackgroundColor: string;
  outcomeBorderColor: string;
  media: Media;
};

const defaultTheme: Partial<Theme> = {
  headingColor: 'highlight.blue.t100',
  headingSize: 'heading4',
  outcomeBackgroundColor: '#eff7ff',
  outcomeBorderColor: '#d8edff',
  media,
};

export const useTheme = (): Theme => useEmotionTheme<Theme>();

function getTheme(countryCode: CountryCode): Theme {
  return {
    ...defaultTheme,
    ...countrySpecific[countryCode],
    media,
  } as Theme;
}

export const theme: Theme = getTheme(config.countryCode);
