import { CountryCode, PhoneNumberId } from 'types';

export const countryCode = CountryCode.AU;
export const countryName = 'Australia';
export const phoneNumbers = {
  [PhoneNumberId.general]: '0800 500 505',
  [PhoneNumberId.generalAlt]: '+61 9 555 4432',
};

// TODO: NZP-XXX - Need to verify these phone number regex patterns are robust enough
// AU Mobile numbers start with 04 or 05, followed by 8 numbers
export const validMobilePattern = '^0[45]\\d{8}$';
