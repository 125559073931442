import React from 'react';
import { useHistory } from 'react-router-dom';
import { Text, Button } from 'basis';
import { LayoutPage, LayoutForm } from 'components';

export const Documents: React.FC = () => {
  const history = useHistory();
  return (
    <LayoutPage step="upload">
      <LayoutForm>
        <Text textStyle="heading1">DOCUMENTS UPLOAD PLACEHOLDER</Text>
        <Button type="button" variant="secondary" onClick={history.goBack}>
          Back
        </Button>
      </LayoutForm>
    </LayoutPage>
  );
};
