import React from 'react';
import { Stepper } from 'basis';

type Props = {
  step: string;
};

export const ProgressStepper: React.FC<Props> = ({ step }) => {
  return (
    <Stepper>
      <Stepper.Item label="Login" label-md="Login" current={step === 'login'} />
      {/* <Stepper.Item label="Choose" label-sm="Choose" current={step === 'choose'} minor /> */}
      <Stepper.Item label="Update" label-sm="Update" current={step === 'update'} />
      {/* <Stepper.Item label="Processing" label-sm="Processing" current={step === 'processing'} minor /> */}
      <Stepper.Item label="Outcome" label-sm="Outcome" current={step === 'outcome'} />
    </Stepper>
  );
};

// @ts-ignore
ProgressStepper.ID = 'ProgressStepper';
// @ts-ignore
ProgressStepper.HEIGHT_MAP = {
  default: Stepper.HEIGHT_MAP.default,
};
