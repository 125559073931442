import React from 'react';
import { Header as BasisHeader } from 'basis';
import { config } from '_config';

export const Header: React.FC = () => {
  return (
    <BasisHeader>
      <BasisHeader.Logo name={config.countryCode === 'NZ' ? 'gem' : 'latitude'} />
    </BasisHeader>
  );
};

// @ts-ignore
Header.ID = 'MyHeader';
// @ts-ignore
Header.HEIGHT_MAP = BasisHeader.HEIGHT_MAP;
