import React from 'react';
import { DefaultFooter } from './DefaultFooter';
import { useTrackingEvent } from 'hooks';

const footerLinks = {
  privacy_policy: {
    label: 'Privacy Policy',
    url: 'https://www.latitudefinancial.com.au/privacy',
  },
  terms_and_conditions: {
    label: 'Terms and Conditions',
    url: 'https://www.latitudefinancial.com.au/terms-and-conditions',
  },
  security: {
    label: 'Security',
    url: 'https://www.latitudefinancial.com.au/security',
  },
};

export type FooterLinkKey = keyof typeof footerLinks;
export type FooterLinks = typeof footerLinks;

export const Footer: React.FC = () => {
  const { trackEvent, getQueryString } = useTrackingEvent();

  const trackEventSender = (label: string): void => {
    trackEvent({
      category: 'text-link',
      action: 'internal-link',
      location: 'footer',
      label,
    });
  };

  const getLinkWithAnalytics = (key: FooterLinkKey): string => {
    const gemid2 = getQueryString({
      category: 'text-link',
      action: 'internal-link',
      location: 'footer',
      label: footerLinks[key].label,
    });

    return `${footerLinks[key].url}?${gemid2}`;
  };

  return (
    <DefaultFooter
      trackEventSender={trackEventSender}
      getLinkWithAnalytics={getLinkWithAnalytics}
      footerLinks={footerLinks}
    />
  );
};
