import { State } from 'types';
import { config } from '_config';

const defaultState: State = {
  countryCode: config.countryCode,
  assessmentId: '',
  correlationId: '',
  accessToken: '',
  latitudeId: '',
  error: null,
  contactDetails: {
    mobileNumber: '',
    emailAddress: '',
    confirmEmail: '',
  },
  verification: {
    verificationLocked: false,
    maskedMobileNumber: '',
    oktaAuthTxn: null,
  },
  fiConnect: {
    userToken: '',
    guid: '',
  },
  tealium: {
    poiStartDate: '',
    bankSelected: '',
    numberOfAccountsSelected: '',
    numberOfTransactionsSelected: '',
  },
  complete: false,
};

export const initialState = defaultState;
