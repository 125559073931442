import { oktaAuthClient } from './oktaAuthClient';
import jwtDecode from 'jwt-decode';
import { AuthTransaction } from '@okta/okta-auth-js/lib/tx';

export const getSubFromToken = (jwt?: string): string => {
  try {
    // @ts-ignore
    return jwt ? jwtDecode(jwt).sub : '';
  } catch (e) {
    return '';
  }
};

export const getAuthHeader = (token?: string): string | undefined => {
  const accessToken = token || oktaAuthClient.getAccessToken() || process.env.REACT_APP_OKTA_TOKEN;
  return accessToken ? `Bearer ${accessToken}` : undefined;
};

export const getLatitudeId = (token?: string): string | undefined => {
  const accessToken = token || oktaAuthClient.getAccessToken();
  return getSubFromToken(accessToken);
};

type SmsFactor = { verify: ({ passCode }?: { passCode: string }) => Promise<AuthTransaction> };

export const findSMSFactor = (authTxn: AuthTransaction): SmsFactor => {
  return authTxn.factors?.find((factor) => factor.provider === 'OKTA' && factor.factorType === 'sms') as SmsFactor;
};
