import { OktaAuth } from '@okta/okta-auth-js';
import { OktaAuthOptions } from '@okta/okta-auth-js/lib/types';

const config: OktaAuthOptions = {
  issuer: `${process.env.REACT_APP_OKTA_DOMAIN}/oauth2/${process.env.REACT_APP_OKTA_ISSUER_ID}`,
  clientId: process.env.REACT_APP_OKTA_CLIENT_ID,
  storageManager: {
    token: {
      storageTypes: ['memory'],
    },
  },
};

export const oktaAuthClient = new OktaAuth(config);

// Expose getAccessToken for cypress tests;
if (window.Cypress) {
  window.getAccessToken = () => oktaAuthClient.getAccessToken();
}
