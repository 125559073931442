import { useState } from 'react';
import axios from 'axios';
import { DEV_FEATURES } from '__dev';
import { useAppContext } from './useAppContext';
import { useOktaClient } from './useOktaClient';
import { useHandleApiErrors } from './useHandleApiErrors';
import { ApiError, ApiType } from 'types';
import { setVerification } from 'context';
import { formatMaskedMobileNumber, toInternationalMobileNumber } from 'utils';
import { config } from '_config';

const registerURL = process.env.REACT_APP_USER_REGISTER_URL as string;

type SubmitArgs = { email: string; mobile: string };

type UseRegisterAndLoginUser = {
  loading: boolean;
  submit: ({ email, mobile }: SubmitArgs) => Promise<void>;
};

type UseRegisterAndLoginUserArgs = {
  onSuccess?: () => void;
  onError?: (error: ApiError) => void;
};

export const useRegisterAndLoginUser = ({
  onSuccess,
  onError,
}: UseRegisterAndLoginUserArgs): UseRegisterAndLoginUser => {
  const { dispatch, state } = useAppContext();
  const [loading, setLoading] = useState(false);
  const { handleError } = useHandleApiErrors(ApiType.axios, onError);
  const { loginAndSendOTP } = useOktaClient({ onError });

  const submit = async ({ email, mobile }: SubmitArgs) => {
    const mobilePhone = DEV_FEATURES.OTP_MOBILE_NUMBER || toInternationalMobileNumber(mobile, config.countryCode);
    const payload = {
      profile: {
        email,
        mobile_phone: mobilePhone,
      },
    };

    const options = {
      headers: {
        'x-correlation-id': state.assessmentId,
        'lfs-request-channel': process.env.REACT_APP_OKTA_CLIENT_ID,
      },
    };

    try {
      setLoading(true);

      const registerData = await axios.post(registerURL, payload, options);

      dispatch(
        setVerification({
          maskedMobileNumber: formatMaskedMobileNumber(
            registerData.data.profile.masked_mobile_phone,
            config.countryCode,
          ),
        }),
      );

      const success = await loginAndSendOTP(email);

      setLoading(false);
      success && onSuccess && onSuccess();
    } catch (err) {
      setLoading(false);
      handleError(err);
    }
  };

  return {
    loading,
    submit,
  };
};
