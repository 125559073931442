import React from 'react';
import { Text, Stack } from 'basis';
import { LayoutPage, LayoutOutcomePage } from 'components';
import { Routes } from 'types';
import { useTheme } from 'theme';
import { config } from '_config';

export const Outcome: React.FC = () => {
  const theme = useTheme();
  return (
    <LayoutPage step="outcome" paddingBottom={0}>
      <LayoutOutcomePage useConfetti>
        <Stack gap="8">
          <Text as="h1" textStyle="heading5" textStyle-md="heading4" color={theme.headingColor}>
            {config.routeConfig[Routes.outcome].pageTitle}
          </Text>
          <Text>
            We have what we need for now and we will be in touch with you shortly to discuss the next steps, either by email or phone.
          </Text>
        </Stack>
      </LayoutOutcomePage>
    </LayoutPage>
  );
};
