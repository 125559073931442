import { useCallback } from 'react';
import { getSessionStorage } from 'utils';
import { devFeaturesStorageKey } from '__dev';

type EnvObject = { [key:string]: string }

export const useEnv = (): (envName?: string) => string | EnvObject => {
  const getEnv = useCallback((envName?: string):  string | EnvObject => {
    if (!envName) {
      if (process.env.REACT_APP_ENV === 'prod') {
        return process.env as EnvObject;
      }
      return { ...process.env, ...getSessionStorage(devFeaturesStorageKey, 'ENV_OVERRIDES') };
    }

    if (process.env.REACT_APP_ENV === 'prod') {
      return process.env[envName] as string;
    }
    return getSessionStorage(devFeaturesStorageKey, 'ENV_OVERRIDES')?.[envName] || process.env[envName] as string;
  }, []);

  return getEnv;
};
