import { CountryCode, PhoneNumberId } from 'types';

export const countryCode = CountryCode.NZ;
export const countryName = 'New Zealand';
export const phoneNumbers = {
  [PhoneNumberId.general]: '0800 500 505',
  [PhoneNumberId.generalAlt]: '+64 9 362 5432',
};
// NZ Mobile numbers start with 02, followed by 7 - 9 numbers
export const validMobilePattern = '^02\\d{7,9}$';
