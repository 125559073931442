import { initMessagesObject, Messages } from 'utils';
import { FormValidateOptions, FormValues } from 'components';
import { FieldConfigValidateProps } from './initFormConfig';

export type FormValidateFnc = (value: string, options: FormValidateOptions) => string | null;

export const validation = (
  { minLength, maxLength, matchField, validPattern }: FieldConfigValidateProps,
  msg: Messages,
): [FormValidateFnc, Messages] => {
  const messages = initMessagesObject(msg, {
    minLength,
    maxLength,
    matchField: matchField?.label,
  });
  const validRegExp = validPattern ? new RegExp(validPattern) : undefined;

  return [
    (value: string, options: FormValidateOptions) => {
      return stringTypeValidation(value, options.data, minLength, maxLength, matchField, validRegExp, messages);
    },
    messages,
  ];
};

const stringTypeValidation = (
  value: string,
  data: { values?: FormValues; codeLength?: number },
  minLength?: number,
  maxLength?: number,
  matchField?: { name: string; label: string },
  validRegExp?: RegExp,
  messages: Messages = {},
): string | null => {
  const valueLength = value.trim().length;

  if (!valueLength) {
    return messages.required;
  }
  if (validRegExp && !validRegExp.test(value)) {
    return messages.invalid;
  }
  if (minLength && valueLength < minLength) {
    return messages.minLength;
  }
  if (maxLength && valueLength > maxLength) {
    return messages.maxLength;
  }
  if (matchField && data.values?.[matchField.name] !== value) {
    return messages.matchField;
  }
  return null;
};
