import { validation, Messages } from 'utils';
import { FormFieldConfig } from 'components';

export type FieldConfigValidateProps = {
  minLength?: number;
  maxLength?: number;
  validPattern?: string;
  matchField?: { name: string; label: string };
};
type FieldConfig = {
  type?: string;
  name: string;
  label?: string;
  testId?: string;
  helpText?: string;
  validate?: {
    props: FieldConfigValidateProps;
    messages: Messages;
  } | null;
  pasteAllowed?: boolean;
  fields?: FieldConfig[];
};

const withTransformedValidate = (field: FieldConfig): FormFieldConfig => {
  if (field.validate === undefined) {
    return field as FormFieldConfig;
  }

  if (field.validate === null) {
    return { ...field, validate: false } as FormFieldConfig;
  }

  return {
    ...field,
    maxLength: field.validate.props?.maxLength,
    validate: validation(field.validate.props, field.validate.messages)[0],
  } as FormFieldConfig;
};

export const initFormConfig = (fields: FieldConfig[]): FormFieldConfig[] => {
  return fields.reduce<FormFieldConfig[]>((acc, field) => {
    if (field.fields) {
      return [...acc, { ...field, fields: initFormConfig(field.fields) } as FormFieldConfig];
    }
    return [...acc, withTransformedValidate(field)];
  }, []);
};
