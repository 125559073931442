import React from 'react';
import { Grid, Text } from 'basis';
import { useTheme } from 'theme';
import { LayoutSection } from 'components';

type Props = {
  children: React.ReactNode;
};

export const PageTitle: React.FC<Props> = ({ children }) => {
  const theme = useTheme();
  return (
    <LayoutSection>
      <Grid rowsGap="8">
        <Text as="h2" margin="0 0 2 0" textStyle={theme.headingSize} color={theme.headingColor}>
          {children}
        </Text>
      </Grid>
    </LayoutSection>
  );
};
