import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Link, Container, Text } from 'basis';
import { useAppContext, useTrackingEvent } from 'hooks';
import { Routes } from 'types';
import { setError, setTealium } from 'context';
import { LayoutPage, InformationCard, LayoutSection } from 'components';
import { FiConnectModal, UploadDocumentsModal, UploadPayslipsModal } from '../modals';
import { config } from '_config';

import ICON_FI_CONNECT from 'images/vi_share_your_bank_statement.svg';
import ICON_DOCUMENTS from 'images/vi_upload_other_documents.svg';
import ICON_PAYSLIPS from 'images/vi_upload_your_payslips.svg';

export enum Option {
  NONE = '',
  FI_CONNECT = 'FI_CONNECT',
  DOCUMENTS = 'DOCUMENTS',
  PAYSLIPS = 'PAYSLIPS',
}

export const mainConfig = {
  [Option.FI_CONNECT]: {
    title: 'Share your bank statement',
    label: 'Share bank statement',
    modalLinkLabel: 'Safely and securely verify your income.',
  },
  [Option.DOCUMENTS]: { title: 'Upload other documents', label: 'Upload documents', modalLinkLabel: 'Find out more' },
  [Option.PAYSLIPS]: { title: 'Upload your payslips', label: 'Upload payslips', modalLinkLabel: 'Find out more' },
};

// TODO: Update url with correct query string https://di.latitudefinancial.com/jira/browse/NZP-720
const URL_UPLOAD_DOCS = 'https://upload.gemfinance.co.nz/fileupload/income?cardType=gemvisanz';

export const Main: React.FC = () => {
  const history = useHistory();
  const { trackEvent } = useTrackingEvent();
  const { dispatch } = useAppContext();
  const [openModal, setOpenModal] = useState<Option>(Option.NONE);

  useEffect(() => {
    dispatch(setError(null));
    // eslint-disable-next-line
  }, []);

  const handleLinkClick = (evt: React.SyntheticEvent<HTMLAnchorElement>): void => {
    evt.preventDefault();

    dispatch(
      setTealium({
        poiStartDate: new Date().toISOString(),
      }),
    );

    trackEvent({
      category: 'application',
      action: 'application-navigation',
      location: mainConfig[Option.FI_CONNECT].title,
      label: mainConfig[Option.FI_CONNECT].label,
    });

    history.push(new URL(evt.currentTarget.href).pathname);
  };

  const handleUploadLinkClick = (type: Option.DOCUMENTS | Option.PAYSLIPS): void => {
    trackEvent({
      category: 'text-link',
      action: 'internal-link',
      location: mainConfig[type].title,
      label: mainConfig[type].label,
    });
  };

  const handleOpenModal = (evt: React.SyntheticEvent<HTMLAnchorElement>): void => {
    evt.preventDefault();
    const type = new URL(evt.currentTarget.href).hash.replace('#', '') as Option;
    if (type !== Option.NONE) {
      trackEvent({
        category: 'text-link',
        action: 'interaction',
        location: mainConfig[type].title,
        label: mainConfig[type].modalLinkLabel,
      });
    }
    setOpenModal(type);
  };
  const handleCloseModal = (): void => {
    setOpenModal(Option.NONE);
  };

  return (
    <LayoutPage step="update">
      <LayoutSection>
        <Text margin="0 0 4 0">{config.routeConfig[Routes.main].pageTitle}</Text>
        <Text>
          <strong>The fastest way to get an outcome</strong>
        </Text>
        <InformationCard
          icon={ICON_FI_CONNECT}
          title={mainConfig[Option.FI_CONNECT].title}
          linkLabel={mainConfig[Option.FI_CONNECT].label}
          linkHref={Routes.connect}
          onClick={handleLinkClick}
          testId="fi-connect"
        >
          <Text textStyle="body1">
            <strong>We could give you a decision now</strong>, by securely logging in and sharing your bank statement
            online.{' '}
            <Link
              href={`#${Option.FI_CONNECT}`}
              newTab={false}
              onClick={handleOpenModal}
              testId="fi-connect-modal-link"
            >
              {mainConfig[Option.FI_CONNECT].modalLinkLabel}
            </Link>
          </Text>
        </InformationCard>
      </LayoutSection>

      <LayoutSection>
        <Container>
          <Text>Other ways to prove your income</Text>
        </Container>

        <InformationCard
          icon={ICON_PAYSLIPS}
          title={mainConfig[Option.PAYSLIPS].title}
          linkLabel={mainConfig[Option.PAYSLIPS].label}
          linkHref={URL_UPLOAD_DOCS}
          onClick={() => handleUploadLinkClick(Option.PAYSLIPS)}
          newTab
          testId="upload-payslips"
        >
          <Text textStyle="body1">
            We’ll automatically process your uploaded payslips.{' '}
            <Link
              href={`#${Option.PAYSLIPS}`}
              newTab={false}
              onClick={handleOpenModal}
              testId="upload-payslips-modal-link"
            >
              {' '}
              {mainConfig[Option.PAYSLIPS].modalLinkLabel}.
            </Link>{' '}
            <strong>This gives you a decision within 1-2 days.</strong>
          </Text>
        </InformationCard>

        <InformationCard
          icon={ICON_DOCUMENTS}
          title={mainConfig[Option.DOCUMENTS].title}
          linkLabel={mainConfig[Option.DOCUMENTS].label}
          linkHref={URL_UPLOAD_DOCS}
          onClick={() => handleUploadLinkClick(Option.DOCUMENTS)}
          newTab
          testId="upload-documents"
        >
          <Text textStyle="body1">
            You can manually upload your bank statements, IRD summary of earnings and financial statements.{' '}
            <Link
              href={`#${Option.DOCUMENTS}`}
              newTab={false}
              onClick={handleOpenModal}
              testId="upload-documents-modal-link"
            >
              {mainConfig[Option.DOCUMENTS].modalLinkLabel}.
            </Link>{' '}
            <strong>This gives you a decision within 2 days.</strong>
          </Text>
        </InformationCard>
      </LayoutSection>
      {openModal === Option.FI_CONNECT && <FiConnectModal onClose={handleCloseModal} />}
      {openModal === Option.PAYSLIPS && <UploadPayslipsModal onClose={handleCloseModal} />}
      {openModal === Option.DOCUMENTS && <UploadDocumentsModal onClose={handleCloseModal} />}
    </LayoutPage>
  );
};
