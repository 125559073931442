import { Routes } from '../types';

let configModule;

switch (process.env.REACT_APP_COUNTRY_CODE) {
  case 'NZ':
    configModule = require('./nz');
    break;
  case 'AU':
    configModule = require('./au');
    break;
  default:
    configModule = null;
}

export const validEmailPattern =
  '^(([^<>()[\\]\\\\.,;:\\s@"]+(\\.[^<>()[\\]\\\\.,;:\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$';
export const emailMaxLength = 255;

export const ROUTE_CONFIG = {
  [Routes.init]: {
    siteSubSection: 'poi-process',
    pageTitle: 'Verify',
    tealiumPageTitle: 'contact-details',
  },
  [Routes.verify]: { siteSubSection: 'poi-process', pageTitle: 'Verify', tealiumPageTitle: 'verify' },
  [Routes.main]: {
    siteSubSection: 'poi-process',
    pageTitle: 'NZ legislation requires us to verify your income.',
    tealiumPageTitle: 'poi-landing',
  },
  [Routes.connect]: { siteSubSection: 'poi-process', pageTitle: 'fi-connect', tealiumPageTitle: 'bank-connect' },
  [Routes.documents]: { siteSubSection: 'poi-process', pageTitle: 'documents', tealiumPageTitle: 'documents' },
  [Routes.payslips]: { siteSubSection: 'poi-process', pageTitle: 'payslips', tealiumPageTitle: 'payslips' },
  [Routes.outcome]: {
    siteSubSection: 'poi-process',
    pageTitle: 'Thanks for providing your bank statements!',
    tealiumPageTitle: 'referred',
  },
  [Routes.acceptance]: { siteSubSection: 'pca-process', pageTitle: 'Offer Acceptance', tealiumPageTitle: 'acceptance' },
  [Routes.acceptanceConfirmation]: { siteSubSection: 'pca-process', pageTitle: 'Thank you for accepting the offer', tealiumPageTitle: 'acceptance-confirmation' },
};

export const config = {
  ...configModule,
  validEmailPattern,
  emailMaxLength,
  routeConfig: ROUTE_CONFIG,
};

export * from './tealiumConfig';
export * from './features';
