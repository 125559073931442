import React from 'react';
import { useHistory } from 'react-router-dom';
import { Container, Text, Link } from 'basis';
import { submitHandler } from 'utils';
import {
  LayoutPage,
  LayoutForm,
  Form,
  Error,
  FormState,
  FormData,
  OneTimeCode,
  PageTitle,
  LayoutSection,
} from 'components';
import { useOktaClient, useAppContext, useTrackingError, useTrackingEvent } from 'hooks';
import { DEV_FEATURES } from '__dev';
import { ApiError, ErrorType, Routes } from 'types';
import { setVerification, setError } from 'context';
import { config } from '_config';

export const VERIFICATION_LOCKED_TITLE = 'Unable to verify your identity';

const RESEND_MIN = 0.5;
const FORM_ID = 'verification';
const initialValues = {
  verificationCode: '',
};

export const Verification: React.FC = () => {
  const { state, dispatch } = useAppContext();
  const history = useHistory();
  const { trackEvent } = useTrackingEvent();
  const { trackError } = useTrackingError();

  const onStartAgainClick = () => {
    trackEvent({
      category: 'application',
      action: 'application-navigation',
      location: 'verify',
      label: 'back',
    });
  };

  const onResendCodeClick = () => {
    trackEvent({
      category: 'application',
      action: 'application-navigation',
      location: 'verify',
      label: 'resend code',
    });
  };

  const onResendCodeError = (error: ApiError) => {
    trackError({ error });
  };

  const onOTPSuccess = () => {
    if (state.assessmentId !== '') {
      history.push(Routes.main);
    } else if (state.correlationId !== '') {
      history.push(Routes.acceptance);
    }
  };

  const handleVerifyOTPSuccess = () => {
    onOTPSuccess();
  };

  const handleVerifyOTPError = (error: ApiError) => {
    if (error.type === ErrorType.OKTA_VERIFICATION_LOCKED_ERROR) {
      dispatch(setVerification({ verificationLocked: true }));
      trackError({ error, location: VERIFICATION_LOCKED_TITLE });
    } else {
      dispatch(setError(error));
      trackError({ error });
    }
  };

  const { loading, verifyOTP, applySyntheticToken } = useOktaClient({
    onSuccess: handleVerifyOTPSuccess,
    onError: handleVerifyOTPError,
  });

  const onSubmit = ({ values, errors, setErrors }: FormState): void => {
    async function verifyOTPAndRedirect() {
      if (DEV_FEATURES.OTP_SKIP) {
        const success = await applySyntheticToken();
        success && onOTPSuccess();
      } else {
        const verifyOTPResponse = await verifyOTP(values.verificationCode);
        if (typeof verifyOTPResponse === 'object' && verifyOTPResponse?.errorMessage) {
          setErrors({ verificationCode: verifyOTPResponse.errorMessage });
        }
      }
    }

    trackEvent({
      category: 'application',
      action: 'application-navigation',
      location: 'verify',
      label: 'Verify',
    });

    submitHandler({ submit: verifyOTPAndRedirect, errors });
  };

  if (state.error) {
    return (
      <LayoutPage step="login">
        <Error />
      </LayoutPage>
    );
  }

  if (state.verification.verificationLocked) {
    return (
      <LayoutPage step="login">
        <PageTitle>VERIFICATION_LOCKED_TITLE</PageTitle>
        <LayoutSection>
          <Container>
            <Text>
              <strong>You have exceeded the maximum attempts allowed.</strong>
            </Text>
          </Container>
          <Container>
            <Text>As a result, we're unable to process your request at this time.</Text>
          </Container>
          <Container padding="8 0 0 0">
            <Link
              appearance="primary-button"
              variant="blue-button"
              href={Routes.init}
              newTab={false}
              onClick={onStartAgainClick}
            >
              Restart application
            </Link>
          </Container>
        </LayoutSection>
      </LayoutPage>
    );
  }

  return (
    <LayoutPage step="login">
      <PageTitle>{config.routeConfig[Routes.verify].pageTitle}</PageTitle>
      <Form id={FORM_ID} initialValues={initialValues} onSubmit={onSubmit} loading={loading} submitButtonLabel="Verify">
        {(formData: FormData) => {
          return (
            <LayoutForm>
              <OneTimeCode
                name="verificationCode"
                label="Verification code"
                codeLength={6}
                resendMsecs={RESEND_MIN * 60000}
                maskedMobileNumber={state.verification.maskedMobileNumber}
                onResendCodeClick={onResendCodeClick}
                onResendCodeError={onResendCodeError}
                formData={formData}
              />
            </LayoutForm>
          );
        }}
      </Form>
    </LayoutPage>
  );
};
