import { ApiError, ContactDetails, AuthDetails, VerificationDetails, FiConnectDetails, TealiumDetails } from 'types';

export enum Actions {
  SET_CONTACT_DETAILS = 'SET_CONTACT_DETAILS',
  SET_AUTH = 'SET_AUTH',
  SET_VERIFICATION = 'SET_VERIFICATION',
  INIT_FI_CONNECT = 'INIT_FI_CONNECT',
  SET_TEALIUM = 'SET_TEALIUM',
  SET_ERROR = 'SET_ERROR',
  SET_COMPLETE = 'SET_COMPLETE',
  RESET = 'RESET',
}

type SetContactDetails = {
  type: Actions.SET_CONTACT_DETAILS;
  data: Partial<ContactDetails>;
};

export function setContactDetails(details: Partial<ContactDetails>): SetContactDetails {
  return {
    type: Actions.SET_CONTACT_DETAILS,
    data: details,
  };
}

type SetAuth = {
  type: Actions.SET_AUTH;
  data: Partial<AuthDetails>;
};

export function setAuth(auth: Partial<AuthDetails>): SetAuth {
  return {
    type: Actions.SET_AUTH,
    data: auth,
  };
}

type SetVerification = {
  type: Actions.SET_VERIFICATION;
  data: Partial<VerificationDetails>;
};

export function setVerification(data: Partial<VerificationDetails>): SetVerification {
  return {
    type: Actions.SET_VERIFICATION,
    data,
  };
}

type SetFiConnect = {
  type: Actions.INIT_FI_CONNECT;
  data: {
    userToken: string;
    guid: string;
  };
};

export function setFiConnect(data: FiConnectDetails): SetFiConnect {
  return {
    type: Actions.INIT_FI_CONNECT,
    data,
  };
}

type SetTealium = {
  type: Actions.SET_TEALIUM;
  data: Partial<TealiumDetails>;
};

export function setTealium(data: Partial<TealiumDetails>): SetTealium {
  return {
    type: Actions.SET_TEALIUM,
    data,
  };
}

type SetError = {
  type: Actions.SET_ERROR;
  error: ApiError | null;
};

export function setError(error: ApiError | null): SetError {
  return {
    type: Actions.SET_ERROR,
    error,
  };
}

type SetComplete = {
  type: Actions.SET_COMPLETE;
  complete: boolean;
};

export function setComplete(complete: boolean): SetComplete {
  return {
    type: Actions.SET_COMPLETE,
    complete,
  };
}

type Reset = {
  type: Actions.RESET;
};

export function reset(): Reset {
  return {
    type: Actions.RESET,
  };
}

export type Action =
  | SetContactDetails
  | SetAuth
  | SetVerification
  | SetFiConnect
  | SetTealium
  | SetError
  | SetComplete
  | Reset;
