/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Container, Grid } from 'basis';
import { Error, LayoutPage, LayoutSection, Loading } from 'components';
import FiConnectComponent, { Config, FiConnectEvent, FiConnectEventTypes, Region } from 'fi-connect';
import { ThemeHeadingColor, ThemeHeadingSize } from 'fi-connect/lib/context';
import { useTheme } from 'theme';
import { InitFiConnectData, useAppContext, useInitFiConnect, useTrackingEvent, useTrackingError } from 'hooks';
import { ApiError, ErrorType, Routes } from 'types';
import { setComplete, setError, setFiConnect, setTealium } from 'context';
import { datadogRum } from '@datadog/browser-rum';
import styled from '@emotion/styled';

export const FiConnect: React.FC = () => {
  const theme = useTheme();
  const history = useHistory();
  const { trackEvent } = useTrackingEvent();
  const { trackError } = useTrackingError();
  const { state, dispatch } = useAppContext();

  const handleSuccess = (data: InitFiConnectData): void => {
    dispatch(
      setTealium({
        ...state.tealium,
      }),
    );

    dispatch(setFiConnect(data));
  };

  const handleError = (error: ApiError): void => {
    dispatch(setError(error));
    trackError({ error });
  };

  const { loading, initFiConnect } = useInitFiConnect({ onSuccess: handleSuccess, onError: handleError });

  useEffect(() => {
    if (!state.complete) {
      void initFiConnect();
    }
  }, []);

  const hasInitialised = !!(state.fiConnect.userToken && state.fiConnect.guid);

  const handleEvent = (evt: FiConnectEvent): void => {
    console.warn('handleEvent', JSON.stringify(evt, null, 2));
    switch (evt.type) {
      case FiConnectEventTypes.ANALYTICS: {
        const { bankSelected, numberOfAccountsSelected, numberOfTransactionsSelected } = evt.data;

        trackEvent({
          ...evt.data.event,
          data: {
            ...state,
            tealium: {
              poiStartDate: state.tealium.poiStartDate,
              bankSelected,
              numberOfAccountsSelected,
              numberOfTransactionsSelected,
            },
          },
        });

        dispatch(
          setTealium({
            bankSelected,
            numberOfAccountsSelected,
            numberOfTransactionsSelected,
          }),
        );

        break;
      }
      case FiConnectEventTypes.RESET: {
        void initFiConnect();
        break;
      }
      case FiConnectEventTypes.ERROR: {
        const error = {
          type: ErrorType.FI_CONNECT_ERROR,
          error: { message: evt.error, code: evt.errorCode ? evt.errorCode.toString() : '' },
        };
        dispatch(setError(error));
        datadogRum.addError(error, undefined);
        break;
      }
      case FiConnectEventTypes.COMPLETE: {
        dispatch(setComplete(true));
        history.push(Routes.outcome);
        break;
      }
    }
  };

  const handleBackToMainPage = () => {
    dispatch(
      setTealium({
        poiStartDate: '',
      }),
    );

    trackEvent({
      category: 'application',
      action: 'application-navigation',
      location: 'fi-connect',
      label: 'back',
    });
    history.push('/main');
  };

  const config: Config = useMemo(
    () => ({
      countryCode: Region.NZ,
      guid: state.fiConnect.guid,
      userToken: state.fiConnect.userToken,
      onEvent: handleEvent,
      theme: {
        headingSize: theme.headingSize as ThemeHeadingSize,
        headingColor: theme.headingColor as ThemeHeadingColor,
      },
      initialBackButton: (
        <Button type="button" variant="secondary" onClick={handleBackToMainPage}>
          Back
        </Button>
      ),
      additionalErrorMessage: (
        <>
          {' '}If you’re having trouble logging in, you can{' '}
          <StyledAdditionalErrorMessage onClick={handleBackToMainPage}>
            upload payslips, or other documents.
          </StyledAdditionalErrorMessage>
        </>
      ),
      errorFallbackButton: (
        <Container padding="6 0">
          <Grid rowsGap="4" colsGap="2">
            <Grid.Item colSpan="all" alignItems="center">
              <Grid>
                <Button testId="ErrorFallbackButton" type="button" variant="secondary" onClick={handleBackToMainPage}>
                  Or, choose another option
                </Button>
              </Grid>
            </Grid.Item>
          </Grid>
        </Container>
      ),
      formButtonsLayout: 'columns',
    }),
    [state.fiConnect.guid, state.fiConnect.userToken],
  );

  if (state.error) {
    return (
      <LayoutPage step="upload">
        <Error fiConnect={state.error.type === ErrorType.FI_CONNECT_ERROR} />
      </LayoutPage>
    );
  }

  return (
    <LayoutPage step="upload">
      <LayoutSection>
        {loading || !hasInitialised ? (
          <Loading text="Initialising" />
        ) : (
          <FiConnectComponent
            config={config}
            environment={process.env.REACT_APP_ENV === 'prod' ? 'production' : 'development'}
          />
        )}
      </LayoutSection>
    </LayoutPage>
  );
};

const StyledAdditionalErrorMessage = styled.div({
  display: 'inline',
  cursor: 'pointer',
  textDecoration: 'underline',
  color: '#066AFF',
});
