import React from 'react';
import { Link } from 'basis';
import { config } from '_config';
import { PhoneNumberId } from 'types';

type Props = {
  id: PhoneNumberId;
  onClick?: () => void;
  appearance?: string;
  variant?: string;
  width?: string;
  prefix?: string;
};

export const PhoneNumberLink: React.FC<Props> = ({ id, onClick, appearance, variant, width, prefix }) => {
  const displayNumber = id ? config.phoneNumbers[id] : config.phoneNumbers.general;
  const hrefNumber = displayNumber.replace(/\s/g, '');

  return (
    <Link
      href={`tel:${hrefNumber}`}
      newTab={false}
      onClick={onClick}
      appearance={appearance}
      variant={variant}
      {...(width ? { width } : null)}
    >
      {prefix ? `${prefix} ${displayNumber}` : displayNumber}
    </Link>
  );
};
