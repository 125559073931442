import React from 'react';
import { Button, Container, Text, Flex, List } from 'basis';
import { Modal } from 'components';
import { useTheme } from 'theme';

const modalStyle = {
  width: 600,
};

type Props = {
  isOpen?: boolean;
  onClose: () => void;
};

export const UploadPayslipsModal: React.FC<Props> = ({ onClose }) => {
  const theme = useTheme();
  return (
    <Modal onClose={onClose} style={modalStyle} testId="modal-payslips">
      <Container padding="3" padding-md="8">
        <Text textStyle="heading4" color={theme.headingColor} margin="0 0 6 0" margin-md="0 0 7 0">
          Upload payslips
        </Text>

        <Container>
          <Text textStyle="body1">
            <b>We’ll automatically scan your documents</b>
          </Text>
        </Container>

        <Container padding="6 0 1 0">
          <Text textStyle="body2">We’ll need you to upload 2 consecutive payslips that clearly show:</Text>
        </Container>

        <Container padding="6 0">
          <List textStyle="body2">
            <List.Item>Your full name</List.Item>
            <List.Item>Your employer’s name </List.Item>
            <List.Item>Most recent payment is dated within the last 31 days</List.Item>
            <List.Item>Shows net/gross income </List.Item>
          </List>
        </Container>

        <Flex placeItems="center">
          <Container padding="2">
            <Button width="160px" onClick={onClose}>
              Close
            </Button>
          </Container>
        </Flex>
      </Container>
    </Modal>
  );
};
