import React from 'react';
import { Button, Container, Text, Flex, List, Link } from 'basis';
import { Modal } from 'components';
import { useTheme } from 'theme';

const modalStyle = {
  width: 600,
};

type Props = {
  isOpen?: boolean;
  onClose: () => void;
};

export const UploadDocumentsModal: React.FC<Props> = ({ onClose }) => {
  const theme = useTheme();
  return (
    <Modal onClose={onClose} style={modalStyle} testId="modal-documents">
      <Container padding="3" padding-md="8">
        <Text textStyle="heading4" color={theme.headingColor} margin="0 0 6 0" margin-md="0 0 7 0">
          Upload other documents
        </Text>

        <Container>
          <Text textStyle="body1">
            <b>If you don’t have easy access to your payslips</b>
          </Text>
        </Container>

        <Container padding="6 0">
          <Container padding="0 0 2 0">
            <Text textStyle="body2">Other types of documents you can upload:</Text>
          </Container>
          <List textStyle="body2">
            <List.Item>Your most recent bank statements, showing 2 months of income</List.Item>
            <List.Item>IRD summary of earnings for the previous 12 months</List.Item>
          </List>
        </Container>

        <Container>
          <Text textStyle="body1">
            <b>If you’re self-employed</b>
          </Text>
        </Container>

        <Container padding="6 0">
          <Container padding="0 0 2 0">
            <Text textStyle="body2">The best way to prove your income is by uploading one of the following:</Text>
          </Container>
          <List textStyle="body2">
            <List.Item>IRD summary of earnings for the previous 12 months</List.Item>
            <List.Item>Profit and loss statement</List.Item>
          </List>
        </Container>

        <Container padding="0 0 6 0">
          <Text textStyle="body2">
            For a full list and more information on acceptable ways to prove your income{' '}
            <Link href="#" newTab={false}>
              click here
            </Link>
          </Text>
        </Container>

        <Flex placeItems="center">
          <Container padding="2">
            <Button width="160px" onClick={onClose}>
              Close
            </Button>
          </Container>
        </Flex>
      </Container>
    </Modal>
  );
};
