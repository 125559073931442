import React from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import { useAppContextSelector, usePrevious } from 'hooks';
import { Routes } from 'types';
import { DEV_FEATURES } from '__dev';

export const RouterRedirectManager = () => {
  const { accessToken, complete } = useAppContextSelector((ctx) => ctx.state);
  const { pathname } = useLocation();
  const prevPathname = usePrevious(pathname);

  const preAuthRoutes = [Routes.init, Routes.verify];
  const authRoutes = [Routes.main, Routes.connect, Routes.documents, Routes.payslips, Routes.acceptance, Routes.acceptanceConfirmation];
  const completeRoutes = [Routes.outcome];

  const accessibleRoutes = complete ? completeRoutes : accessToken ? authRoutes : preAuthRoutes;

  if (
    DEV_FEATURES.ALLOW_NAVIGATION_TO_ALL_ROUTES ||
    pathname === prevPathname ||
    accessibleRoutes.includes(pathname as Routes)
  ) {
    return null;
  }

  const redirectPath = prevPathname ? prevPathname : !accessToken ? Routes.init : Routes.main;

  return <Redirect to={redirectPath} />;
};
