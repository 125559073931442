import React from 'react';
import { useHistory } from 'react-router-dom';
import { LayoutPage, LayoutForm, Form, Error, PageTitle } from 'components';
import { initFormConfig, submitHandler } from 'utils';
import { useRegisterAndLoginUser, useAppContext, useTrackingEvent, useTrackingError } from 'hooks';
import { DEV_FEATURES } from '__dev/devFeatures';
import { config } from '_config';
import { LoginFields } from './LoginFields';
import { FormState, FormData } from 'components';
import { setContactDetails, setError } from 'context';
import { ApiError, ContactDetails as ContactDetailsType, ErrorType, Routes } from 'types';

const FORM_ID = 'contactDetails';

const mobileNumberValidationMessages = {
  required: 'Please enter a 9-11 digit phone number.',
  invalid: 'Mobile number must begin with 02 followed by 7-9 numbers in total with no spaces or dashes',
};

const emailValidationMessages = {
  required: 'Please enter a valid email address.',
  matchField: 'Email addresses do not match - please re-enter your email address.',
  maxLength: 'Must have at most {{maxLength}} characters',
  invalid: 'Please enter in the format of name@example.com',
};

const validationMessages = {
  mobileNumber: mobileNumberValidationMessages,
  emailAddress: emailValidationMessages,
  confirmEmail: emailValidationMessages,
};

const fields = [
  {
    type: 'Input',
    name: 'mobileNumber',
    label: 'Mobile number',
    testId: 'mobile-number',
    helpText: `Only New Zealand mobile phone numbers are accepted.`,
    validate: { props: { validPattern: config.validMobilePattern }, messages: validationMessages.mobileNumber },
  },
  {
    type: 'Input',
    name: 'emailAddress',
    label: 'Email address',
    testId: 'email-address',
    validate: {
      props: { maxLength: config.emailMaxLength, validPattern: config.validEmailPattern },
      messages: validationMessages.emailAddress,
    },
  },
  {
    type: 'Input',
    name: 'confirmEmail',
    label: 'Confirm email address',
    testId: 'confirm-email-address',
    validate: {
      props: {
        maxLength: config.emailMaxLength,
        validPattern: config.validEmailPattern,
        matchField: { name: 'emailAddress', label: 'Email address' },
      },
      messages: validationMessages.confirmEmail,
    },
    pasteAllowed: false,
  },
];

export const formConfig = initFormConfig(fields);
const initialValues = {
  mobileNumber: '',
  emailAddress: '',
  confirmEmail: '',
};

export const Login: React.FC = () => {
  const { state, dispatch } = useAppContext();
  const history = useHistory();
  const { trackEvent } = useTrackingEvent();
  const { trackError } = useTrackingError();

  const handleSubmitSuccess = () => {
    history.push(Routes.verify);
  };

  const handleSubmitError = (error: ApiError) => {
    dispatch(setError(error));
    trackError({ error });
  };

  const { loading, submit } = useRegisterAndLoginUser({
    onSuccess: handleSubmitSuccess,
    onError: handleSubmitError,
  });

  const onSubmit = ({ values, errors }: FormState) => {
    function registerAndLoginUser() {
      const uniqueEmail = DEV_FEATURES.OTP_GENERATE_UNIQUE_EMAIL
        ? values.emailAddress.replace(/\d*@/, `${Date.now()}@`)
        : '';

      const contactDetails = {
        ...values,
        ...(uniqueEmail
          ? {
              emailAddress: uniqueEmail,
              confirmEmail: uniqueEmail,
            }
          : null),
      } as ContactDetailsType;

      dispatch(setContactDetails(contactDetails));

      const { emailAddress: email, mobileNumber: mobile } = contactDetails;
      DEV_FEATURES.OTP_SKIP ? handleSubmitSuccess() : submit({ email, mobile });
    }

    trackEvent({
      category: 'application',
      action: 'application-navigation',
      location: 'contact-details',
      label: 'next',
      data: {
        ...state,
        contactDetails: {
          emailAddress: values.emailAddress,
          confirmEmail: values.confirmEmail,
          mobileNumber: values.mobileNumber,
        },
      },
    });

    submitHandler({ submit: registerAndLoginUser, errors });
  };

  const retrySubmit = async (): Promise<void> => {
    const { emailAddress: email, mobileNumber: mobile } = state.contactDetails;
    await submit({ email, mobile });
  };

  if (state.error) {
    const retry = [ErrorType.OKTA_LOGIN_AND_SEND_OTP_ERROR].includes(state.error.type) ? { retry: retrySubmit } : null;
    return (
      <LayoutPage step="login">
        <Error {...retry} retrying={loading} />
      </LayoutPage>
    );
  }

  return (
    <LayoutPage step="login">
      <PageTitle>{config.routeConfig[Routes.init].pageTitle}</PageTitle>
      <Form
        id={FORM_ID}
        initialValues={initialValues}
        onSubmit={onSubmit}
        loading={loading}
        submitButtonLabel="Continue"
      >
        {(formData: FormData) => {
          return (
            <LayoutForm>
              <LoginFields config={formConfig} formData={formData} disabled={loading} />
            </LayoutForm>
          );
        }}
      </Form>
    </LayoutPage>
  );
};
