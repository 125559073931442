import React, { useEffect } from 'react';
import { ThemeProvider } from 'emotion-theming';
import { BasisProvider, defaultTheme as basisTheme } from 'basis';
import { useSiteTracking } from 'react-event-tracker';
import { HelmetProvider } from 'react-helmet-async';
import { trackingConfig } from '_config';
import { ContextProvider } from 'context';
import { DevFeatureToggle } from '__dev';
import { useDatadog, useTealium } from 'hooks';
import { ErrorBoundary, Metadata } from 'components';
import { theme } from 'theme';
import { Router } from './Router';
import { getSessionStorage } from 'utils';
import { updateDataDogContext } from './hooks/useDatadog';

const ID_PARAM = 'assessmentid';
const CORRELATION_ID_PARAM = 'correlationid';

const App: React.FC = () => {
  const url = new URL(window.location.href);
  const assessmentId = url.searchParams.get(ID_PARAM) || getSessionStorage('state', 'assessmentId') || '';
  const correlationId = url.searchParams.get(CORRELATION_ID_PARAM) || getSessionStorage('state', 'correlationId') || '';

  useEffect(() => {
    updateDataDogContext({ assessmentId, correlationId });
  }, [assessmentId, correlationId]);

  useDatadog();
  useTealium();

  const { SiteTracking } = useSiteTracking(trackingConfig);

  return (
    <HelmetProvider>
      <Metadata />
      <SiteTracking>
        <BasisProvider theme={basisTheme}>
          <ThemeProvider theme={theme}>
            <ContextProvider state={{ assessmentId, correlationId }}>
              <ErrorBoundary>
                <Router />
                <DevFeatureToggle />
              </ErrorBoundary>
            </ContextProvider>
          </ThemeProvider>
        </BasisProvider>
      </SiteTracking>
    </HelmetProvider>
  );
};

export default App;
